
@font-face {
  font-family: "DIN Next Slab";
  src: url(./Fonts/81cf49f1-f04d-41a6-920b-752df5b71583.woff2) format("woff2"),url(./Fonts/1ff92c4e-9e48-4ff8-8510-ef39bb4a3858.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DIN Next Slab";
  src: url(./Fonts/43096214-12d8-4d04-b379-7f99052a5ebe.woff2) format("woff2"),url(./Fonts/b6b7aa94-7edc-4562-9fe0-2034124cc1d6.woff) format("woff");
  font-weight: 600;
  font-style: oblique;
  font-display: swap;
}


@font-face {
  font-family: Calibri;
  src: url(./Fonts/17c31e5c-5784-4ae6-8cec-a6caed2afd3f.woff2) format("woff2"),url(./Fonts/0555dc6b-1bb5-4cdc-8975-756197067e95.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calibri;
  src: url(./Fonts/69222be9-c9d5-4171-b3cd-f4c1697d4e75.woff2) format("woff2"),url(./Fonts/ac71c358-4cfa-4488-a823-3d4a086ab35c.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calibri;
  src: url(./Fonts/d3a0bdd1-8fef-4e49-a808-6bf63cc5ffd0.woff2) format("woff2"),url(./Fonts/f29d2fec-cc28-4d35-88a0-7c81385c6e79.woff) format("woff");
  font-weight: 700;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: Calibri;
  src: url(./Fonts/fdf371b8-1ed3-4c8c-a647-5c57f889f586.woff2) format("woff2"),url(./Fonts/42f91b8c-9815-4407-a659-bd79b1104da2.woff) format("woff");
  font-weight: 400;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: Calibri;
  src: url(./Fonts/20685c06-277c-434c-9362-96bd3446e057.woff2) format("woff2"),url(./Fonts/c107f5db-1c4f-46e7-9a18-36ab736d2d6e.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calibri;
  src: url(./Fonts/59ecfa15-5100-4bb8-ad20-7765c46be031.woff2) format("woff2"),url(./Fonts/aa7b5c03-ccdd-4b3d-a712-b6715743a8ea.woff) format("woff");
  font-weight: 200;
  font-style: oblique;
  font-display: swap;
}


/*Spot Color Objects:
PANTONE 2945 C (Lab)#004c97	azul
PANTONE 361 C (Lab)#43B02A	verde
*/

.servicios-comercializacion{
  font-size: 1.25rem;
  bottom:2.5%; 
  left:10%;
}

.texto-xl{
  font-size:4rem;
}
.texto-l{
  font-size:3rem;
}
.texto-m{
  font-size:1.4rem;
}
.texto-s{
  font-size:1rem;
}
.texto-xs{
  font-size:0.5rem;
}
.texto-white{
  color: white;
}
.texto-green{
  color: #43B02A !important;
}
.texto-gray{
  color: #8d8d8d;
}
.atexto-blue{
  color: #004c97;
}
.texto-bolder{
  font-family: "DIN Next Slab";
}
.texto-bold{
  font-family: "DIN Next Slab";
}
.texto-normal{
  font-family: "Calibri";
  font-weight: 200;
}
.texto-light{
  font-family: "Calibri";
  font-weight: 200
}
.texto-harabara{
  font-family: "DIN Next Slab";
}
.sustentabilidad{
  width: 40%;
}
.sustentabilidad li::before { 
  content: "";
  border-color: transparent white;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1em;
  position: relative;
}

.sustentabilidad li:last-child::before{
  content: "";
  border-color: transparent transparent;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1em;
  position: relative;

}

.timeline-data{
  width:80%; 
  position:relative;
  display:flex; 
  flex-direction:row;
  box-sizing: border-box;
}
.timeline-image{
  width:50%; 
  height:100%; 
  left:0;
}
.timeline-image img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position: center;
}
.timeline-post{
  width: 50%; 
  height:100%; 
  right:0; 
  display:flex; 
  flex-direction:column; 
  align-items:flex-start; 
  box-sizing:border-box; 
  padding:5%; 
  text-align:left; 
  font-size:'1rem';
}
.timeline-selectors{
  display:flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin:2rem auto;
  flex-wrap:nowrap;
  cursor: pointer;
  font-size: 1.5rem;
  color:white;
  font-weight: 400;
}
.timeline-selector{
  display:flex;
  width: calc(100% / 6);
  border:none;
  background-color:rgba(61,174,43,0.23);
  color: #3DAE2B;
  min-height: 4.5rem;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timeline-selector:hover{
  opacity:1;
  color:white;
  font-weight: 800;
  background-color:rgba(61,174,43,1);

}
.timeline-selector-active{
  opacity:1;
  color:white;
  font-family: "DIN Next Slab";
  font-weight: 600;
  background-color:rgba(61,174,43,1);

}
.timeline-selector-active span{
  border-bottom: solid 3px white;

}
body {
  margin: 0  auto;
  width: 100%;
  max-width: 1920px;
  text-align: center;
  color:white; 
  font-family: "Calibri";
  font-weight: 200;
}

.cacahuates{
  width:100%;
  display:flex;
  flex-direction:row; 
  justify-content:space-between; 
  padding:2rem 0;
}

.cacahuate{
  width:calc(20% - 10px);
  display:flex; 
  flex-direction:column;
  align-items:center; 
  justify-content:space-evenly; 
  border:5px solid white; 
  padding:1rem; 
  height: 460px;
}

#encabezado {
	color: white;
	top:0;
  width: 100%;
  max-width: 1920px;
  position: fixed;
  z-index:10000;
  background-image: linear-gradient(rgba(0,0,0,0.80),rgba(0,0,0,0)); 
}
.sticky{
  background-color: white;
  color:#43B02A;
}
.sticky{
  columns: #43B02A !important;
}

.blog-link{
  text-decoration: none;
  color: #43B02A;
}

.blog-link:hover{
  color:#004c97;
}

.blog-post{
  box-sizing: border-box;
  width: 20%;
  margin-top: 2rem;
  display:flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: space-between;
}

.langSelector{
  background-color: transparent;
  color: white;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top:1rem;
  height:2rem;
}

.langItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}

#contenido{  
  display:flex;
  flex-direction: column;  
  background: white;
  width: 100%;
}

.Contenedor {
  display:flex;
  flex-direction: column;    
  text-decoration: none;  
  
  align-items: center;
  justify-content: center;

  width: 100%;
}

.Encabezado{
 width:100%; 
 display: flex; 
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 font-family: "DIN Next Slab";
 transition: background-color 600ms ease;
 padding: 0 2.5rem;
}
.HeaderPrincipal{
  height: 500px;
  min-height: 500px;
}
.selectors{
  display:flex;
  flex-direction: row;
  position:absolute;
  bottom:5%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.selector-dot{
  box-sizing: border-box;
  color:#8d8d8d;
  background-color: white;
  border-color:white;
  opacity: 0.50;
  border-radius: 50%;
  height:1rem;
  width:1rem;
  padding:0.5rem;
  margin: 0 1rem;
  cursor: pointer;
  outline: none;
}
.selector-dot.dot-active{
  color:white;
  opacity: 1;;
}

.Encabezado  a{
  color: white;
  font-family: "Calibri";
  font-weight: 200
}
.sticky a{
  color: #43B02A;
}
.Encabezado .selected{
  color:white;
  font-family: "Calibri";
  font-weight: 700;
  border-bottom: 2px solid white;
}
.sticky  .selected{
  color: #43B02A;
  font-family: "Calibri";
  font-weight: 700;
  border-bottom: 2px solid #43B02A;
}
.dropdown-content{
  display:none;
  text-transform: uppercase;
}
.dropdown ul, 
.dropdown li{
  list-style: none;
  padding:0;
  margin:0;
}

.dropdown{
  position: relative;
}
.dropdown>ul {
  display: none;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top:1rem;
  padding-top:50px;

}

.dropdown > ul>li>a {
  display: inline-block;
  padding: 10px 20px;
  color:#3DAE2B;
  font-family: "Calibri";
  font-weight: 200;
  text-decoration: none;
}
.dropdown > ul > li:hover > a{
  color: white;
}
.dropdown > ul > li{
  background-color: white;
  color: #3DAE2B;
  border-bottom: 1px solid darkgray;

}
.dropdown > ul > li:hover{
  background-color: #004c97;
  color:white;
}

.dropdown> ul:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: 0;
  height: 0;
  content: '';
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
}

.dropdown:hover>ul {
  display: inline;
}

.BannerA{  
  position: relative;
  width: 100%;
  background-position: center center;
  padding:1rem;
  height: 100%;
  min-height: 350px;
  background-size: cover;
}


.BannerInformativo{    
  padding: 0px;
  text-align: left;
  margin:auto;
  height: fit-content;
  width: 100%;
  font-family: "DIN Next Slab";
  box-sizing: border-box;
  font-weight: 200; 
  padding: 1rem;
 
}
  .Separador{
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0px;
    height: 5px;;
    width: 100%;
    }
.BannerB{
  background-color:lightgreen;
  min-height: 120px;
  width: 100%;
  margin-bottom: 10px;
}

.Cuadricula{    
  margin-top: 1.5em;
  overflow-x: auto;
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}

.Cuadro{
  display: flex;
  margin:4px;
  color:white;
  width: 25%;
  box-sizing: border-box;
  font-family: "DIN Next Slab";  
  background-color:rgb(59, 59, 59);
  background-blend-mode:multiply;
  position:relative;
  text-align:center;
  flex-shrink: 0;
  
}

.Cuadro > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Cuadro > span {
  z-index:9999;
  background-blend-mode:normal;
}
.Cuadro:hover > span{
  background-blend-mode:normal;
}

.Cuadro{
  overflow: hidden;
}

.Cuadro:hover > span:after{
  opacity: 1;
}

.Cuadro:hover img{
  transform: scale(1.25);
}
.Cuadro img{
  max-width: 100%;
  transition: all 2.0s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}



.Cuadro > span:after {
  position: absolute;
  left: 0;
  bottom: -0.5em;
  width: 100%;
  border-bottom: 0.25em solid white;
  content: "";
  opacity: 0;
}


.Cuadro:hover:after{
  height: 35%;
  top: calc(50% - 17.5%);
}

.Cuadro:after {
  content: '\A';
  position: absolute;
  width: 100%; 
  height: 35%;
  top: calc(50% - 17.5%);
  left:0;
  background:rgba(75,75,75,0.6);
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.BannerH{ 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: auto;
}

.BarraOpciones{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  justify-content:center;
  justify-items: first baseline;
  background-size:cover;
  background-position: center center;
  background-repeat: no-repeat;
    
}
  
.Concepto{
  display:flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}


.Rectangulo{
  border-color: darkblue;
  background:  darkblue;
  height: 90px;
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
}
.Circulo{
display:flex;
flex-direction: column;
justify-content: center;
justify-items:baseline;
min-width: 70px;
min-height: 70px;
/*-moz-border-radius:50%;
-webkit-border-radius:50%;
border-radius: 50%;*/
background: transparent;
background-size:contain;
background-position: center;
background-repeat: no-repeat;
margin-left: 0px;
margin-right: 0px;
border:0 auto;
color:#8d8d8d;

}

.Cuadrado{
  border:gray solid thin;
  width: 180px;
  height: 180px;
  background: lightgray;
  margin-left: 5px;
  margin-right: 5px;
  }
  

hr{
  border: 1px solid;
}

.Link{
  color:black; 
  text-decoration: none;
  cursor:hand;
}

.Link:hover{
  font-family: "Calibri";
  font-weight: 700;
}

.LinkButton{
   background-color:transparent;
   text-align: center;   
   font-weight: bold;
   color:#8d8d8d;
   text-decoration: none;
   cursor: pointer; 
   border: #8d8d8d solid;
   display: flex;
   flex-direction: column;
}

.LinkVentajas{
  background-color:transparent;
  text-align: center;   
  font-weight: bold;
  color:white;
  text-decoration: none;
  cursor: pointer; 
  border:0px;
  font-family:Calibri;
  font-weight: 400;
  font-size: 3em;
}
.LinkVentajas:hover{
 font-size: 3.5em;
 filter:opacity(100%);
}
.Encabezado .Link:hover{
  border-bottom: 2px solid white;
}


.transparencia{
  filter:opacity(50%);
}
.LinkButtonAzul{
  background-color:#004c97;
  margin-top: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;   
  font-weight: bold;
  color:white;
  text-decoration: none;
  cursor: pointer; 
  border:#004c97 solid;
  margin-bottom: 20px;
  z-index:10010;
}


.Contactanos{
  width: fit-content; 
  margin-right: 1em;
  display: grid;
  grid-template-columns: repeat(2,18em);
  grid-template-rows: repeat(7,2.5em);
  height: fit-content;
  justify-items: end;
}


.Cotizacion{  
  background-color:white;
  width: 100%; 
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

.Cotizacion h2{
  color: #3DAE2B;
  font-family: "DIN Next slab";
  text-transform: uppercase;
  font-size: 1.5rem;
}
.cotizacion-input{
  width:80%;
  display: flex;
  flex-direction: column;
  padding:1rem;
}

.Leyenda{
  font-weight: bold;  
  font-family: "Calibri";
  color: #8d8d8d;
  outline:none; 
  text-align: left;
  font-size:1rem;
}

.Texto{
  font-family:"Calibri";
  text-align: left;
  font-size:1rem;
  width:100%;
}
.Texto input{
  width: 100%;
  font-size: 1rem;
  color: #8d8d8d;
  border: none;
  border-bottom: 2px solid #8d8d8d;
}

.Texto textarea{
  width: 100%;
  height: 3rem;
  border: 2px solid #8d8d8d;
  font-size: 1rem;
  font-family: "Calibri";
}

.Cotizacion a{
  background-color:transparent;
  text-align: center;   
  font-weight: bold;
  color:#8d8d8d;
  text-decoration: none;
  cursor: pointer; 
  border: 2px solid #8d8d8d;
  padding: 1rem;
  font-size: 1rem;
  width: 25%;
}

.PieDePagina{    
  display: flex;
  flex-direction: column;  
  background-color:#25450E;    
  width: 100%; 
  color:white;
  text-align:left;
  padding-bottom: 1em;
  min-height: 230px;
  justify-content: space-between;
}
.footer-info{
  margin-top:4em;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.footer-left{
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  text-align:center;
  width: 30%;
}
.footer-left > a{
  color:white;
  width: 100%;
}
.footer-center{
  display:flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-left: 2px solid rgba(255,255,255,0.37);
  border-right: 2px solid rgba(255,255,255,0.37);
}
.footer-center > img{
  width:50%;
}
.footer-final{
  display:flex;
  flex-direction:column;
  align-items:center;
}
.footer-right{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
}
.footer-data{
  display:flex;
  flex-direction: column;;
}
.Banner2cols{ 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content:center;
  align-items: center;
  align-content: center;
  gap: 0px;
  margin: 0px auto;
}
.BannerFlechas{ 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  justify-content:center;
  align-items: center;
  }

.Cotizar{
  margin: 20px 0px 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  justify-content:flex-start;
  
}
.Banner2rows{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content:flex-start;
  gap: 30px;
  margin: 0px;
}
.Mosaico1{
  overflow-x: auto;
  margin: 10px auto;
  max-width: 800px; 
  min-width: 400px;
 height: fit-content;
 display: grid;
 grid-template-areas: "A1 A2 A2 A2 A2"
                      "A1 A3 A3 A4 A4"
                      "A5 A5 A6 A6 A6"
                      ;
 grid-column-gap: 10px;
 grid-row-gap: 10px;
 min-height: fit-content;
 
 }
 .A1{
   border:black solid thin;
   background: lightgray;
   grid-area: A1;
   width: 280px;
   height: 370px;
 }
 .A2{border:black solid thin;
  background: lightgray;
  grid-area: A2;
  width: 500px;
  height: 160px;
}
.A3{border:black solid thin;
  background: lightgray;
  grid-area: A3;
  height: 180px;
}
.A4{border:black solid thin;
  background: lightgray;
  grid-area: A4;
  height: 180px;
  width:  220px;
  
}
.A5{border:black solid thin;
  background: lightgray;
  grid-area: A5;
  width: 460px;
   height: 180px;

}
.A6{border:black solid thin;
  background: lightgray;
  grid-area: A6;
  width: 320px;
   height: 180px;
}

.Mosaico2{
  
  margin: 10px auto;
  width: calc(100% - 4rem);
  padding:2rem;

 height: fit-content;
 display: grid;
 grid-template-areas: "B1 B2 B2"
                      "B1 B3 B4"
           
                      
                      ;
 grid-column-gap: 10px;
 grid-row-gap: 10px;
 

 
 
 }
 .B1{
   grid-area: B1;
   display: inline-block;
   color:white;
   font-family: "DIN Next Slab";  
   background-color:rgb(59, 59, 59);
   background-blend-mode:multiply;
   position:relative;
   text-align:center; 
 }
 .B2{
  grid-area: B2;
 }
.B3{
  grid-area: B3;
 
}
.B4{
  grid-area: B4; 
}

.gridEl > div{
  transition: all 1s;
  -webkit-transition: all 1s;
  opacity:0;
}
.gridEl{
  display: inline-block;
  color:white;
  font-family: "DIN Next Slab";
  font-weight: 600;  
  background-color:rgb(59, 59, 59);
  background-blend-mode:multiply;
  position:relative;
  text-align:center;
}

.gridEl > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gridEl > span {
  z-index:9999;
}
.gridEl:hover > span{
  background-blend-mode:normal;
}

.gridEl:hover > span:after{
  opacity: 1;
}
.gridEl:hover > div{
  opacity: 1;
}
.gridEl > span:after {
  position: absolute;
  left: 0;
  bottom: -0.5em;
  width: 100%;
  border-bottom: 0.25em solid white;
  content: "";
  opacity: 0;
}


.gridEl:hover:after{
  opacity: 0;
}

.gridEl:after {
  content: '\A';
  position: absolute;
  width: 100%; 
  height: 100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
}


.flecha{text-align: center;
  font-size: calc(1em*3 );
cursor:pointer;
}

h2{text-align: left;
  height: fit-content;
  border:0px;
  margin: 0 auto;
  padding:0px;
  font-weight: 200;
}

.flotante{
  float: right; 
  margin: 10px 0;
}

.ScrollAuto{
  overflow:auto;  
}
.Modal{ 
  position: fixed;
  top:0px;
  right:0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:10003;
  box-sizing: border-box;
}
.modal-wrapper{
  width:60%;
}
.productModal{
  display:flex;
  flex-direction: row;
  width:100%;
  background-color: white;;
}
.productModalImage{
  width: 50%;
  display:flex;
  flex-direction: column;
  border-right: solid 8px #3DAE2B;
  box-sizing: border-box;
}
.productModalImage > img{
  width:100%;
  height: 100%;
  object-fit: cover;;
}
.productModalText{
  width:50%;
  color: #8d8d8d;
  box-sizing: border-box;
  font-family: "DIN Next Slab";
  padding:2rem;
  height: 100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.productModalText > h2{
  color: #3DAE2B;
  font-size:3rem;
  margin: 0;
}
.productModalText > p{
  font-size: 1rem;
  text-align: justify;
}
.productModalText > a{
  margin-top: auto;
  color: #3DAE2B;
  justify-self: start;
  bottom:2rem;
}
.verde{
  color:#43B02A;
}
.azul{
color:#004c97;
}
.blanco{
  color:white;
}
.gris{
  color: #8d8d8d;
}
#triangle-right {
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-left: 40px solid white;
  border-bottom: 40px solid transparent;
  position: absolute;
  transform: translateY(-50%);
}
#triangle-left {
  top: 50%;
  right: 0px;
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-right: 40px solid white;
  border-bottom: 40px solid transparent;
  position: absolute;
  transform: translateY(-50%);
}
.LineaBlanca{

   margin-top:50px;
    height: 2px;
    width: 50px;
    background-color:white;
  }

.blog{
  width: 100%;
  display:flex;
  flex-direction: row;
}
.blog-page-italic{
  font-style: italic;
}
.blog-page-blue{
  color:#698CB2;
}
.blog-page-center{
  text-align: center;
}
.blog-page-author{
  border-top: 1px solid #DCB8A4;
}
.blog-page{
  width: calc(70%);
  text-align: justify;
  font-family: "DIN Next Slab";
  font-weight: 600;
  display:flex;
  flex-direction: column;
  color:rgb(77,77,77);
  font-size: 1.4rem;
  padding: 2rem 5%;
}

.blog-image{
  width: 30%;
  background-size: cover;
  align-self: stretch;
  background-position: center center;
}


.blog-page-par{
  display:flex;
  flex-direction:column;
  margin-top: 0.50em;
  margin-bottom: 0.50em;
}

.blog-page-par-title{
  color:#43B02A;
  font-weight: 800;
  text-indent: initial;
}

.blog-page-par-gallery{
  width: 25%;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}
  
.blog-page-par-gallery img{
  width:100%;
  height: auto;
}

.arrow{
  cursor: pointer;
}
.share_icon {
  fill: #ffffff !important;
  width: 2rem;
  height: 2rem;
  margin: 0.75rem 0.75rem;
}
button.icon{
  display:none;
}
.embed-container { box-sizing: border-box; width:100%; position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
/*
@media (min-width: 1281px){
  html{
    background-color: #8d8d8d;
  }
  body{
    max-width: 1280px;
  }
  .Contenedor{
    max-width: 1280px;
  }
  #encabezado{
    max-width: 1280px;
  }
}
*/
@media (max-width: 767px) {
  .arrow{
    display:none;
  }
  html{
    font-size: 12px;
  }
  body{
    font-size: 12px;
  }
  .blog-post{
    width: 45%;
  }  
  .blog-post-horizontal{
    width: 45%;
    padding: 0.5em;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .blog-post-horizontal-image{
    width: 100%;
  }
  .blog-post-horizontal-text{
    display:flex;
    flex-direction: column;
    width:90%;
    align-items: center;
    justify-content: center;
    padding:0;
  }
  .blog-post-horizontal-text > a{
    display:none;
  }
  .blog-post-horizontal > a{
    display:flex;
  }
  .blog-page-par-gallery{
    width: 50%;
    display:flex;
    flex-direction: row;
    flex-wrap: unset;
    align-items: center;
  }
  .blog-page-par-gallery > img{
    height: auto;
    object-fit: cover;
    width: 100%;
  }
  .servicios-comercializacion{
    font-size: 0.75rem;
    width:75%;
    bottom:3%;
    left: 5%;
  }
  .paciente-post{
    width: calc(50% - 2em);
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding:1em;
  }
  .Cuadricula{
    flex-wrap: wrap;
  }
  .productModal{
    flex-direction: column;
    width:100%;
    height:fit-content;
  }
  .productModalImage{
    width: 100%;
    border-right: none;
    border-bottom: solid 4px #3DAE2B;
    box-sizing: border-box;
  }
  .productModalImage > img{
    width:100%;
    
    object-fit: cover;
  }
  .productModalText{
    box-sizing: border-box;
    width:100%;
    border-left: none;
    border-top: solid 4px #3DAE2B;
  }
  .productModalText h2{
    font-size: 2rem;
  }
  .texto-s{
    font-size:0.75rem;
  }
  .texto-m{
    font-size:1rem;
  }
  .texto-xl{
    font-size:2rem;
  }
  .texto-l{
    font-size:1.5rem;
  }
  .contact_form{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
  }  
  .contact_form > div{
    width: 100%;
  }
  .Encabezado div:not(:first-child){
    display:none;
  }
  .logo{
    width: 50%;
  }
  .nav-sup{
    background-color: transparent;
    flex-direction: column;
    height:40px;
    margin-top: 0px;
  }
  #nav-menu{
    background-color: white;
  }
  .nav-sup button.icon{
    width: 20%;
    background-color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    border: none;
    height:100%;
  }
  .Contenedor{
    margin-top: 90px;
  }
  .nav-sup button.icon i{
    color: white;
    cursor: pointer;
    border: solid 4px #3DAE2B ;
    border-radius: 10%;
    margin-top:2px;
    background-color: #3DAE2B;
  }

  .nav-sup .Encabezado{
    width:80%;
  }


  .logo-container{
    margin-top:10px;
    width:30%;
  }

  .nav-sup{
    justify-content: space-around;
    align-items: flex-start;
  }

  .Encabezado.responsive {
    position: relative;
    color:#3DAE2B;
  }

  .Encabezado.responsive a{
    color: #3DAE2B;
  }
  .Encabezado.responsive .selected{
    border:none;
  }

  .Encabezado.responsive ul{
    display:none;
  }
  .Encabezado.responsive .dropdown:hover ul{
    display:none;
  }
  .Encabezado.responsive{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:80%;
  }

  .Encabezado.responsive .dropdown{
    display:flex;
    flex-direction: column;
    width:130%;
  }
  .Encabezado.responsive .dropdown a{
    height:40px;
    width:100%;
    font-size: 1rem;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
  }

  .Encabezado.responsive .dropdown a.selected{
    background-color: transparent;
  }

  .Encabezado.responsive .menu-follow{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:130%;
    border: none;
    font-size: 1rem;
  }



  .Encabezado.responsive .menu-follow .follow-us{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width:50px;
  }
  
  .Encabezado.responsive .menu-follow .follow-us .footer-social{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width:100%;
  }

  .Encabezado.responsive .menu-follow .follow-us .footer-social a{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width:20px;
  }

  .Encabezado.responsive .menu-follow .follow-us .footer-social a img{
    width:100%;
  }
  .nav-sup{
    margin:0px 0px;
  }

  .footer-left{
    width:33%;
  }
  .footer-right{
    width:33%;
    padding:1rem;
  }
  .footer-right-data{
    width:90%;
    padding:1.5em 1em;
  }
  textarea{
    min-height:5em;
  }
  .submitButton{
    width: 50%;

    align-self: flex-end;

  }

  .blog-page-banner{
    width: 100%;
    margin: 0 auto;
  }

  .BannerInformativo{    
  }
  .Header{
    font-size:0.8rem;
    height: 300px;
    min-height: 300px;
    width: 100%;
  }
  .HeaderPrincipal{
    height: 200px;
    min-height: 200px;
  }
  .BannerA{  
    min-height: 250px;
  }
  
  .Cuadro{
    width: calc(50% - 10px);
  }
  
  .cacahuates{
    width:100%;
    display:flex;
    flex-direction:row; 
    justify-content:space-between; 
    flex-wrap: wrap;
    padding:2rem 0;
  }
  .cacahuate{
    width: calc(50% - 50px);
    height: 380px;
    margin: 0.5em;
  }
  .Mosaico2{
    display: none;
  }
  .BannerH{
    flex-direction: column;;
  }
  .timeline-banner{
    height: 600px;
  }
  .timeline-data{
    flex-direction: column;
    font-size: 0.8rem;
  }
  .timeline-image{
    height: 60%;
    width: 100%;
  }
  .timeline-post{
    height: 40%;
    width: 100%;
  }
  .timeline-selector{
    font-size: 0.75rem;
    min-height:2rem;
    height:2rem;
  }
  .texto-mapa{
    font-size: 0.45rem;
  }
  .share_icon {
    fill: #ffffff !important;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem 0.5rem;
  }  
  .modal-wrapper{
    width:80%;
  }
  .sustentabilidad{
    width: 80%;
  }
  
}